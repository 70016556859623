class Token {
  async generate({ action }: { action: string }) {
    return await new Promise<string>((resolve, reject) => {
      if (typeof window !== undefined && "grecaptcha" in window) {
        (window.grecaptcha as any).enterprise.ready(async () => {
          try {
            // IMPORTANT: The 'token' that results from execute is an encrypted response sent by
            // reCAPTCHA Enterprise to the end user's browser.
            // This token must be validated by creating an assessment.
            // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment

            resolve(
              await (window.grecaptcha as any).enterprise.execute(
                "6LccJpsoAAAAAPprRFUQifRFiskyR11jSM-B2nZy",
                { action },
              ),
            );
          } catch (e: unknown) {
            if (e instanceof Error) {
              reject(e.message);
            }
          }
        });
      }
    });
  }
}

export const RecaptchaToken = new Token();
