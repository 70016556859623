import React from "react";
import { Icon as ChakraIcon, IconProps } from "@chakra-ui/react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

const Icon = ({ ...rest }: IconProps & FontAwesomeIconProps) => {
  return (
    <ChakraIcon
      as={FontAwesomeIcon}
      cursor={rest.onClick ? "pointer" : undefined}
      {...rest}
    />
  );
};

export default React.forwardRef((rest: IconProps & FontAwesomeIconProps, ref: any) => (
  <Icon ref={ref} {...rest} />
));
