import { updateCacheRecord } from "../../singletons/Util";
import api, { QueryStringParameters, Url } from "../api";
import {
  AccountOptions,
  DownloadRestrictions,
  Service,
  UploadRestrictions,
} from "./service";

export type NotificationPreferences = {
  browser?: {
    onTransferComplete?: boolean;
  };
  server?: {
    onRecipientDownload?: boolean;
  };
  recipient?: {
    onTransferComplete?: boolean;
    onTransferSoonToExpire?: boolean;
  },
  sender?: {
    onTransferComplete?: boolean;
    onTransferDownload?: boolean;
    onFileDownload?: boolean;
  }
};

export interface User {
  id: string;
  name?: string;
  email: string;
  options?: AccountOptions;
  uploadRestrictions?: UploadRestrictions;
  downloadRestrictions?: DownloadRestrictions;
  service?: Service;
  notificationPreferences?: NotificationPreferences;
}

const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<User, { userId: string, params?: QueryStringParameters }>({
      query: ({
        userId,
        params
      }) => ({
        url: `user/${userId}`,
        method: "GET",
        params: params,
      }),
      providesTags: (result, err, { userId }) => {
        return [{ type: "User", id: result?.id }];
      },
    }),
    getAvatar: build.query<string, { userId: string }>({
      async queryFn(arg, api, extraOptions, baseQuery) {
        return {
          data: `${process.env.GATSBY_AVATAR_URL}/${arg.userId}?stamp=${Date.now()}`
        }
      },
      providesTags(result, error, arg, meta) {
        return [{ type: 'Avatar', id: arg.userId }]
      },
    }),
    getUriForAvatarUpload: build.query<Url, undefined>({
      query: () => ({
        url: `url/avatar`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    unsubscribe: build.query<string, { token: string }>({
      query: ({ token }) => ({
        url: `email/unsubscribe`,
        method: "GET",
        params: {
          token
        }
      }),
    }),
    updateUser: build.mutation({
      query: ({
        userId,
        body,
      }: {
        userId: string;
        body: Partial<Omit<User, "id" | "email">>;
      }) => ({
        url: `user/${userId}`,
        method: "PATCH",
        body,
      }),
      transformResponse(baseQueryReturnValue: User) {
        return baseQueryReturnValue;
      },
      async onQueryStarted({ userId, body }, api) {
        try {
          const { data } = await api.queryFulfilled

          updateCacheRecord({ id: userId, body: data, tags: ['User'] }, api)
        } catch {}
      },
    }),
  }),
});

export const { useGetUserQuery, useUpdateUserMutation, useGetUriForAvatarUploadQuery, useGetAvatarQuery, useUnsubscribeQuery } = userApi;

export default userApi;
