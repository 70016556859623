import React from "react";
import { AuthContext } from "../../../providers/AuthProvider";
import { UppyContext } from "../../../providers/UppyProvider";
import { UserContext } from "../../../providers/UserProvider";
import Avatar from "../../common/Avatar/Avatar";
import Box from "../../common/Box/Box";
import Link from "../../common/Link/Link";
import { Menu } from "../../common/Menu/Menu";
import Truncate from "../../common/Truncate";
import { useGetTransferTotalsByUserQuery } from "../../../store/api/transfer";
import Loading from "../../common/Loading";
import APIError from "../../common/Error/APIError";
import prettyBytes from "pretty-bytes";
import Text from "../../common/Text/Text";
import Progress from "../../common/Progress/Progress";
import { Portal } from "@chakra-ui/react";

const ShowSubscription = () => {
  const user = React.useContext(UserContext);

  return (
    <Link to="/profile">
      <Menu.Item
        display="flex"
        alignItems="center"
        gap="1"
        p="0"
        _hover={{ background: "transparent" }}
        _focus={{ background: "transparent" }}
      >
        {user?.service?.name ?? "Free"}
      </Menu.Item>
    </Link>
  );
};

const StorageUsed = () => {
  const user = React.useContext(UserContext);

  const totals = useGetTransferTotalsByUserQuery(
    {
      userId: user!.id,
    },
    { skip: !user },
  );

  const percentage = React.useMemo(() => {
    return user?.options?.maxStorage
      ? ((totals.data?.size ?? 0) / (user?.options?.maxStorage ?? 0)) * 100
      : 0;
  }, [totals.data]);

  const scheme = React.useMemo((): "green" | "yellow" | "red" => {
    if (percentage <= 50) {
      return "green";
    }

    if (percentage > 50 && percentage <= 80) {
      return "yellow";
    }

    return "red";
  }, [percentage]);

  if (totals.isLoading) {
    return <Loading text="Retrieving..." height="100%" />;
  }

  if (totals.isError) {
    return (
      <APIError
        error={totals.error}
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      />
    );
  }

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="center"
      gap="2"
      px='4'
      w="full"
    >
      <Text>
        {prettyBytes(totals.data?.size ?? 0)}/
        {user?.options?.maxStorage
          ? prettyBytes(user?.options.maxStorage)
          : "Unlimited"}
      </Text>

      <Progress
        value={percentage}
        w="full"
        maxW="md"
        colorScheme={scheme}
        rounded="xl"
      />
    </Box>
  )
}

const UserMenu = () => {
  const uppy = React.useContext(UppyContext)
  const { userData, logout: authLogout } = React.useContext(AuthContext);
  const user = React.useContext(UserContext);

  const logout = () => {
    uppy.instance.cancelAll()

    authLogout()
  }

  return (
    <Menu isLazy>
      <Menu.Button>
        <Avatar
          id={user?.id}
          name={
            userData?.UserAttributes.find((attr) => attr.Name === "email")
              ?.Value
          }
          size="sm"
        />
      </Menu.Button>

      <Portal>
        <Menu.List color="blackAlpha.700" zIndex="dropdown">
          <Box
            display="flex"
            alignItems="center"
            gap='4'
            p="3"
          >
            <Box display="flex" flexDir="column" gap="2" flex='1'>
              <Truncate>
                {
                  userData?.UserAttributes.find((attr) => attr.Name === "email")
                    ?.Value
                }
              </Truncate>

              <ShowSubscription />
            </Box>

            <Avatar
              id={user?.id}
              name={
                userData?.UserAttributes.find((attr) => attr.Name === "email")
                  ?.Value
              }
              size="md"
            />
          </Box>

          <StorageUsed />

          <Menu.Divider />

          <Link to="/">
            <Menu.Item>Send files</Menu.Item>
          </Link>

          <Menu.Divider />

          <Link to="/profile">
            <Menu.Item>Profile</Menu.Item>
          </Link>

          <Link to="/transfers">
            <Menu.Item>Transfers</Menu.Item>
          </Link>

          <Menu.Divider />

          <Link to="/plans">
            <Menu.Item>Plans</Menu.Item>
          </Link>

          <Link to="/pay/methods">
            <Menu.Item>Payment Methods</Menu.Item>
          </Link>

          <Menu.Divider />

          <Menu.Item onClick={logout}>Logout</Menu.Item>
        </Menu.List>
      </Portal>
    </Menu>
  );
};

export default UserMenu;
