// React
import React from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import * as Sentry from "@sentry/browser";

// Providers
import FontAwesomeProvider from './src/providers/FontAwesomeProvider'
import { Provider } from 'react-redux'
import store from './src/store/index'
import Logger, { LogLevel } from './src/singletons/Logger'
import DynamicLayout from './src/layout/dynamic'

if (process.env.NODE_ENV !== 'production') {
  Logger.logLevel = LogLevel.Debug
} else {
  Logger.logLevel = LogLevel.Error
}

Sentry.init({
  dsn: 'https://aeccef6972564534aa63703480327485@glitchtip.dessol.com/5',
  environment: process.env.NODE_ENV,
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && event.event_id) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }

    return event;
  }
})

// Temporary fix for dynamic pages created in gatsby-node.
// The issue is caused by the Chakra Plugin usage with the createPages API in Gatsby Node.
export function replaceHydrateFunction() {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export const wrapRootElement = ({ element }) => (
  <RecoilRoot>
    <Provider store={store}>
      <FontAwesomeProvider>
        { element }
      </FontAwesomeProvider>
    </Provider>
  </RecoilRoot>
)

export const wrapPageElement = ({ element }) => {
  return (
    <DynamicLayout>
      { element }
    </DynamicLayout>
  )
}