import React from "react";
import AppLayout from "./app";

// Putting this in gatsby-browser prevents these from being re-rendered on each new page load.
// This is useful as the data being presented by the main layout files doesn't need to change for the lifetime of the app, unless explicitly specified below.
const DynamicLayout = ({ children }: { children: React.ReactNode }) => {
  const pathname = typeof window !== "undefined" ? location.pathname : null;

  if (pathname?.startsWith('/4xx')) {
    return children
  }

  return (
    <AppLayout>
      {children}
    </AppLayout>
  )
}

export default DynamicLayout