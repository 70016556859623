import React from "react";
import {
  Popover as ChakraPopover,
  PopoverAnchor as ChakraPopoverAnchor,
  PopoverArrow as ChakraPopoverArrow,
  PopoverBody as ChakraPopoverBody,
  PopoverCloseButton as ChakraPopoverCloseButton,
  PopoverContent as ChakraPopoverContent,
  PopoverFooter as ChakraPopoverFooter,
  PopoverHeader as ChakraPopoverHeader,
  PopoverTrigger as ChakraPopoverTrigger,
  PopoverArrowProps,
  PopoverBodyProps,
  PopoverCloseButtonProps,
  PopoverContentProps,
  PopoverFooterProps,
  PopoverHeaderProps,
  PopoverProps,
} from "@chakra-ui/react";

const Component = ({ ...props }: PopoverProps) => {
  return <ChakraPopover {...props}>{props.children}</ChakraPopover>;
};

const Trigger = ({ ...props }: { children: React.ReactNode }) => {
  return <ChakraPopoverTrigger>{props.children}</ChakraPopoverTrigger>;
};

const Content = ({ children, ...props }: { children: React.ReactNode } & PopoverContentProps) => {
  return (
    <ChakraPopoverContent {...props}>{children}</ChakraPopoverContent>
  );
};

const Arrow = ({ ...props }: PopoverArrowProps) => {
  return <ChakraPopoverArrow {...props}>{props.children}</ChakraPopoverArrow>;
};

const Header = ({ ...props }: PopoverHeaderProps) => {
  return <ChakraPopoverHeader {...props}>{props.children}</ChakraPopoverHeader>;
};

const CloseButton = ({ ...props }: PopoverCloseButtonProps) => {
  return (
    <ChakraPopoverCloseButton {...props}>
      {props.children}
    </ChakraPopoverCloseButton>
  );
};

const Body = ({ ...props }: PopoverBodyProps) => {
  return <ChakraPopoverBody {...props}>{props.children}</ChakraPopoverBody>;
};

const Footer = ({ ...props }: PopoverFooterProps) => {
  return <ChakraPopoverFooter {...props}>{props.children}</ChakraPopoverFooter>;
};

const Anchor = () => {
  return <ChakraPopoverAnchor />;
};

Component.displayName = "Popover";
Trigger.displayName = "Popover.Trigger";
Content.displayName = "Popover.Content";
Arrow.displayName = "Popover.Arrow";
Header.displayName = "Popover.Header";
CloseButton.displayName = "Popover.CloseButton";
Body.displayName = "Popover.Body";
Footer.displayName = "Popover.Footer";
Anchor.displayName = "Popover.Anchor";

const Popover = Object.assign(Component, {
  Trigger,
  Content,
  Arrow,
  Header,
  CloseButton,
  Body,
  Footer,
  Anchor,
});

export default Popover;
