import React from "react";
import { Client, useGetClientDataQuery } from "../store/api/client";

export const ClientContext = React.createContext<(Client & { setPollFor: (v: number) => void, setPollingInterval: (v: number) => void }) | undefined>(undefined);

const ClientProvider = ({ children }: { children: React.ReactNode }) => {
  const [pollFor, setPollFor] = React.useState<number | undefined>(undefined)
  const [pollingInterval, setPollingInterval] = React.useState(10 * 1000)

  const client = useGetClientDataQuery(
    undefined,
    {
      pollingInterval: pollFor ? pollingInterval : 0
    },
  );

  React.useEffect(() => {
    if (pollFor) {
      setTimeout(() => {
        setPollFor(undefined)
      }, pollFor)
    }
  }, [pollFor, pollingInterval])

  return (
    <ClientContext.Provider
      // @ts-ignore
      value={{
        ...client.data,
        setPollFor,
        setPollingInterval
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export default ClientProvider;
