import api from "../api";

export interface Continent {
  code: string;
  genome_id: number;
  names: Record<string, string>;
}

export interface Country {
  code: string;
  iso_code: string;
  names: Record<string, string>;
}

export interface Region {
  continent: Continent;
  country: Country;
  currency: string
}

export interface Client {
  ip?: string;
  region?: Region;
}

const fileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getClientData: build.query<Client, undefined>({
      query: () => ({
        url: 'client',
        method: 'GET'
      })
    }),
    getRegion: build.query<Region, undefined>({
      query: () => ({
        url: `client/region`,
        method: "GET",
      }),
      providesTags: (result) => {
        return [{ type: "Client", id: result?.continent.code }];
      },
    }),
  }),
});

export const { useGetRegionQuery, useGetClientDataQuery } = fileApi;

export default fileApi;
