import { AwsS3Part } from "@uppy/aws-s3-multipart";
import api from "../api";

const uppyApi = api.injectEndpoints({
  endpoints: (build) => ({
    signPart: build.query<{ url: string }, { uploadId: string, partNumber: number, params: { key: string } }>({
      query: ({
        uploadId,
        partNumber,
        params,
      }) => ({
        url: `uppy/${uploadId}/${partNumber}`,
        method: "GET",
        params,
      }),
    }),
    listParts: build.query<Array<AwsS3Part>, { uploadId: string, params: { key: string } }>({
      query: ({
        uploadId,
        params,
      }) => ({
        url: `uppy/${uploadId}`,
        method: "GET",
        params,
      }),
    }),
    createMultipartUpload: build.mutation<{ uploadId: string, key: string }, { body: { filename: string, type?: string, size: number, meta: Record<string, unknown> } }>({
      query: ({
        body,
      }) => ({
        url: `uppy`,
        method: "POST",
        body,
      }),
    }),
    completeMultipartUpload: build.mutation<{ location: string }, { uploadId: string, body: { Key: string, Parts: Array<AwsS3Part> } }>({
      query: ({
        uploadId,
        body,
      }) => ({
        url: `uppy/${uploadId}`,
        method: "POST",
        body,
      }),
    }),
    abortMultipartUpload: build.mutation<undefined, { uploadId: string, body: { Key: string } }>({
      query: ({
        uploadId,
        body,
      }) => ({
        url: `uppy/${uploadId}`,
        method: "DELETE",
        body,
      }),
    }),
  }),
});

export const {
  useCreateMultipartUploadMutation,
  useAbortMultipartUploadMutation,
  useCompleteMultipartUploadMutation,
  useListPartsQuery,
  useSignPartQuery,
} = uppyApi;

export default uppyApi;
