import React from "react";
import { Button as ChakraButton, ButtonProps } from "@chakra-ui/react";
import Spinner from "./Icon/Spinner";

const Button = React.forwardRef(({ isProcessing, ...props }: { isProcessing?: boolean } & ButtonProps, ref) => {
  return (
    <ChakraButton
      colorScheme="brand"
      isDisabled={isProcessing}
      display="flex"
      gap="2"
      border={props.variant === undefined ? "1px" : undefined}
      borderColor={props.variant === undefined ? "whiteAlpha.600" : undefined}
      boxShadow={props.variant === undefined ? "md" : undefined}
      ref={ref}
      {...props}
    >
      {isProcessing && <Spinner />}

      {props.children}
    </ChakraButton>
  );
})

export default Button;
