import React from "react";
import { SplashScreen } from "../components/common/SplashScreen";
import { useGetUserFromSessionQuery } from "../store/api/session";
import { User } from "../store/api/user";
import { AuthContext } from "./AuthProvider";

export const UserContext = React.createContext<(User & { setPollFor: (v: number) => void, setPollingInterval: (v: number) => void }) | undefined>(undefined);

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoggedIn } = React.useContext(AuthContext);
  const [pollFor, setPollFor] = React.useState<number | undefined>(undefined)
  const [pollingInterval, setPollingInterval] = React.useState(10 * 1000)

  const user = useGetUserFromSessionQuery(
    undefined,
    {
      skip: !isLoggedIn(),
      pollingInterval: pollFor ? pollingInterval : 0
    },
  );

  React.useEffect(() => {
    if (pollFor) {
      setTimeout(() => {
        setPollFor(undefined)
      }, pollFor)
    }
  }, [pollFor, pollingInterval])

  const restrictions = React.useMemo(() => {
    return {
      downloadRestrictions: {
        ...user.data?.service?.downloadRestrictions,
        ...user.data?.downloadRestrictions,
      },
      uploadRestrictions: {
        ...user.data?.service?.uploadRestrictions,
        ...user.data?.uploadRestrictions,
      },
      options: {
        ...user.data?.service?.options,
        ...user.data?.options,
      },
    };
  }, [user.data]);

  if (isLoggedIn() && !user.data) {
    return <SplashScreen text="Getting your user data..." />;
  }

  return (
    <UserContext.Provider
      // @ts-ignore
      value={{
        ...user.data,
        ...restrictions,
        setPollFor,
        setPollingInterval
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
