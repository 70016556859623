import React from "react";
import { Avatar as ChakraAvatar, AvatarProps } from "@chakra-ui/react";
import classNames from "classnames";
import { UserContext } from "../../../providers/UserProvider";
import { useGetAvatarQuery } from "../../../store/api/user";

const Avatar = ({
  id,
  name,
  onClick,
  ...rest
}: {
  id?: string;
  name?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
} & AvatarProps) => {
  const user = React.useContext(UserContext)

  const src = useGetAvatarQuery({
    userId: user!.id
  }, { skip: !user })

  return (
    <ChakraAvatar
      {...rest}
      src={src.data}
      name={user?.name}
      alt={name ? `${name} Avatar` : "An Avatar"}
      onClick={onClick}
      className={classNames(
        {
          "cursor-pointer": !!onClick,
        },
        rest.className,
      )}
    />
  );
};

export default Avatar;
