import { Center, CircularProgress, CircularProgressLabel, Flex, FlexProps, Portal } from "@chakra-ui/react";
import { AuthContext } from "../../providers/AuthProvider";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useCookies } from "react-cookie";
import Icon from "../common/Icon/Icon";
import Link from "../common/Link/Link";
import GeneralMenu from "./Menu/General";
import UserMenu from "./Menu/User";
import { UppyContext } from "../../providers/UppyProvider";
import Popover from "../common/Popover/Popover";
import Text from "../common/Text/Text";
import Button from "../common/Button";
import Box from "../common/Box/Box";

const Navigation = ({ ...rest }: FlexProps) => {
  const { isLoggedIn } = React.useContext(AuthContext);
  const { instance } = React.useContext(UppyContext);
  const pathname = typeof window !== "undefined" ? location.pathname : null;

  const [progress, setProgress] = React.useState(0)
  const [paused, setPaused] = React.useState(false)
  const [cookies] = useCookies(["APP_CONSENT"]);

  const show = React.useMemo(() => {
    return {
      menu: !!cookies.APP_CONSENT,
      logo: true,
      user: !!cookies.APP_CONSENT,
    };
  }, [cookies.APP_CONSENT]);

  React.useEffect(() => {
    const progressHandler = (val: number) => {
      setProgress(val)
    }

    const pauseAllHandler = () => {
      setPaused(true)
    }

    const resumeAllHandler = () => {
      setPaused(false)
    }

    if (Object.values(instance.getState().currentUploads).length > 0) {
      instance.on('progress', progressHandler)
      instance.on('pause-all', pauseAllHandler)
      instance.on('resume-all', resumeAllHandler)
    }

    return () => {
      instance.off('progress', progressHandler)
      instance.off('pause-all', pauseAllHandler)
      instance.off('resume-all', resumeAllHandler)
    }
  }, [instance.getState().currentUploads])

  return (
    <Flex {...rest}>
      {show.menu && (
        <Center>
          <GeneralMenu />
        </Center>
      )}

      {show.logo && (
        <Center flex="1">
          <Link to="/">
            {!show.menu && !show.user && (
              <StaticImage src="../../images/logo.png" alt="MailBigFile Logo" />
            )}

            {show.menu && show.user && (
              <StaticImage
                src="../../images/logoSmall.png"
                alt="MailBigFile Small Logo"
                height={32}
              />
            )}
          </Link>
        </Center>
      )}

      {show.user && (
        <Center alignItems='center' gap='4'>
          {/* <Link href="https://max.mailbigfile.com" target="_blank">
            <Icon icon='user' color='white' size='xl' />
          </Link> */}

          {Object.values(instance.getState().currentUploads).length > 0 && pathname !== '/' && (
            <Popover>
              <Popover.Trigger>
                <CircularProgress value={progress} size={'32px'} cursor='pointer'>
                  <CircularProgressLabel>{progress}%</CircularProgressLabel>
                </CircularProgress>
              </Popover.Trigger>

              <Portal>
                <Popover.Content color="black" w='auto'>
                  <Popover.Arrow />

                  <Popover.Body display='flex' alignItems='center' gap='4' justifyContent='space-between' p='2'>
                    <Text pl='2'>
                      Uploaded {Object.values(instance.getFiles()).filter((file) => file.progress?.uploadComplete).length} of {Object.values(instance.getFiles()).length}
                    </Text>

                    <Box display='flex'>
                      <Button
                        variant='ghost'
                        size='xs'
                        color='black'
                        onClick={() => {
                          if (paused) {
                            instance.resumeAll()
                          } else {
                            instance.pauseAll()
                          }
                        }}
                      >
                        <Icon
                          icon={!paused ? 'pause' : 'play'}
                        />
                      </Button>

                      <Button
                        variant='ghost'
                        size='xs'
                        color='black'
                        onClick={() => {
                          instance.cancelAll()
                        }}
                      >
                        <Icon
                          icon='times'
                        />
                      </Button>
                    </Box>
                  </Popover.Body>
                </Popover.Content>
              </Portal>
            </Popover>
          )}

          {(() => {
            if (isLoggedIn()) {
              return <UserMenu />;
            }

            return (
              <Link to="/login">
                <Icon icon="user" color="white" size="xl" />
              </Link>
            );
          })()}
        </Center>
      )}
    </Flex>
  );
};

export default Navigation;
