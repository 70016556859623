import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit"
import { RootState } from "@reduxjs/toolkit/dist/query/core/apiState"
import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks"
import api, { TagTypes } from "../store/api"
import Logger from "./Logger"

export const createEntries = (draft: any, data: any) => {
  if (draft) {
    if (Array.isArray(draft)) {
      draft.unshift(data)
    } else {
      Object.assign(draft, data)
    }
  }

  return draft
}

type MutateRecordContext = {
  getState: () => RootState<any, any, string>
  dispatch: ThunkDispatch<any, any, AnyAction>
}

export function createCacheRecord<T>({ body, tags }: { body: T, tags: Array<TagTypes> }, context: MutateRecordContext) {
  const patchResults: PatchCollection[] = []

  try {
    const entries = api.util.selectInvalidatedBy(context.getState(), tags)
    for (const entry of entries) {
      patchResults.push(
        context.dispatch(
          // TS doesn't like that endpointName is just a string, when updateQueryData is looking for a particular endpoint set.
          // However, it should always be of that set... because... that's how it came to be.
          // @ts-ignore
          api.util.updateQueryData<T>(entry.endpointName, entry.originalArgs, (draft) => {
            if (draft) {
              if (Array.isArray(draft)) {
                draft.unshift(body)
              } else {
                Object.assign(draft, body)
              }
            }

            return draft
          })
        )
      )
    }
  } catch (e: unknown) {
    Logger.debug('Failed to update cache entries', e)

    patchResults.forEach((result) => result.undo())
  }

  return patchResults
}

export function updateCacheRecord<T>({ id, body, tags }: { id: string, body: T, tags: Array<TagTypes> }, context: MutateRecordContext) {
  const patchResults: PatchCollection[] = []

  try {
    const entries = api.util.selectInvalidatedBy(context.getState(), [...tags, ...tags.map((t) => { return { type: t, id } })])
    for (const entry of entries) {
      patchResults.push(
        context.dispatch(
          // TS doesn't like that endpointName is just a string, when updateQueryData is looking for a particular endpoint set.
          // However, it should always be of that set... because... that's how it came to be.
          // @ts-ignore
          api.util.updateQueryData<T>(entry.endpointName, entry.originalArgs, (draft) => {
            if (draft) {
              if (Array.isArray(draft)) {
                for (let item of draft) {
                  if (item.id === id) {
                    Object.assign(item, body)
                  }
                }
              } else if (draft) {
                Object.assign(draft, body)
              }
            }

            return draft
          })
        )
      )
    }
  } catch (e: unknown) {
    Logger.debug('Failed to update cache entries', e)

    patchResults.forEach((result) => result.undo())
  }

  return patchResults
}

export function deleteCacheRecord({ id, tags }: { id: string, tags: Array<TagTypes> }, context: MutateRecordContext) {
  const patchResults: PatchCollection[] = []

  try {
    const entries = api.util.selectInvalidatedBy(context.getState(), [...tags, ...tags.map((t) => { return { type: t, id } })])
    for (const entry of entries) {
      patchResults.push(
        context.dispatch(
          // TS doesn't like that endpointName is just a string, when updateQueryData is looking for a particular endpoint set.
          // However, it should always be of that set... because... that's how it came to be.
          // @ts-ignore
          api.util.updateQueryData<any>(entry.endpointName, entry.originalArgs, (draft) => {
            if (draft) {
              if (Array.isArray(draft)) {
                draft = draft.filter((item) => item.id !== id);
              } else {
                // @ts-ignore
                delete draft[id]
              }
            }

            return draft
          })
        )
      )
    }
  } catch (e: unknown) {
    Logger.debug('Failed to update cache entries', e)

    patchResults.forEach((result) => result.undo())
  }

  return patchResults
}
