// React/Gatsby
import { BoxProps, useDisclosure, useToast } from "@chakra-ui/react";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React from "react";
import AppBox from "../components/AppBox/AppBox";
import BackgroundBox from "../components/common/Box/Background";
import Box from "../components/common/Box/Box";
import Button from "../components/common/Button";
import FullscreenContainer from "../components/common/Container/FullScreen";
import Checkbox from "../components/common/Input/Checkbox";
import FormControl from "../components/common/Input/FormControl";
import Input from "../components/common/Input/Input";
import Textarea from "../components/common/Input/Textarea";
import Link from "../components/common/Link/Link";
import Modal from "../components/common/Modal/Modal";
import { SplashScreen } from "../components/common/SplashScreen";
import Text from "../components/common/Text/Text";
import { AuthProvider } from "../providers/AuthProvider";
import ClientProvider, { ClientContext } from "../providers/ClientProvider";
import UserProvider from "../providers/UserProvider";
import { RecaptchaToken } from "../singletons/Recaptcha";
import { getErrorMessage } from "../store/api";
import { useCreateFeedbackMutation } from "../store/api/feedback";
import "./app.css";

export const Background = () => {
  const client = React.useContext(ClientContext)

  const background = React.useMemo(() => {
    const background = {
      image: "",
      link: "",
    };

    if (client?.region) {
      const chance = Math.random();

      if (chance > 0.5) {
        background.link = "/plans/?plan=pro";

        if (client?.region.currency.toLowerCase() === "gbp") {
          background.image =
            "https://d713p78z0ghru.cloudfront.net/free/free-backgrounds/ProAccount01.jpg";
        } else if (client?.region.currency.toLowerCase() === "eur") {
          background.image =
            "https://d713p78z0ghru.cloudfront.net/free/free-backgrounds/ProAccount03.jpg";
        } else {
          background.image =
            "https://d713p78z0ghru.cloudfront.net/free/free-backgrounds/ProAccount02.jpg";
        }
      } else {
        background.image =
          "https://d713p78z0ghru.cloudfront.net/free/free-backgrounds/30-day-free.jpg";
        background.link =
          "https://www.mailbigfile.com/business/register-free-trial-account/";
      }
    } else {
      background.image =
      "https://d713p78z0ghru.cloudfront.net/free/free-backgrounds/30-day-free.jpg";
      background.link =
        "https://www.mailbigfile.com/business/register-free-trial-account/";
    }

    return background;
  }, [client?.region]);

  return (
    <Link to={background.link} target="_blank" position="relative">
      <BackgroundBox background={background.image} />
    </Link>
  );
};

const CTAButtons = () => {
  const [createFeedback] = useCreateFeedbackMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      email: "",
      message: "",
      contact: false,
    },
    async onSubmit(values, actions) {
      try {
        const response = await createFeedback({
          body: {
            message: values.message,
            email: values.email,
            contact: values.contact,
            recaptcha: {
              token: await RecaptchaToken.generate({ action: "FEEDBACK" }),
              action: "FEEDBACK",
            },
          },
        });

        if ("error" in response) {
          throw new Error(getErrorMessage(response.error));
        }

        toast({
          title: "Success",
          description: "Thanks for your feedback",
          status: "success",
        });

        onClose();
      } catch (e: any) {
        if (e instanceof Error) {
          if (typeof e.cause === "string") {
            actions.setFieldError(e.cause, e.message);
          } else {
            toast({
              title: "An error occurred",
              description: e.message,
              status: "error",
            });
          }
        }
      } finally {
        actions.setSubmitting(false);
        actions.resetForm();
      }
    },
  });

  return (
    <>
      <Box
        display="flex"
        flexDir="column"
        pos="fixed"
        bottom="4"
        left="4"
        padding="3"
        borderRadius="lg"
        textAlign="center"
        background="linear-gradient(45deg, #625fa2 0%,#e358b4 100%)"
        color="white"
        transition="background-position ease-in-out 0.25s"
        cursor="pointer"
        _hover={{ backgroundPosition: "254px" }}
        maxW={'275px'}
      >
        <Link href="https://free.mailbigfile.com" _hover={{ textDecor: 'unset' }}>Want to use the old free service? <br />Click here to go back to it.</Link>
      </Box>

      <Box
        display="flex"
        flexDir="column"
        pos="fixed"
        bottom="4"
        right="4"
        padding="3"
        borderRadius="lg"
        textAlign="center"
        background="linear-gradient(45deg, #625fa2 0%,#e358b4 100%)"
        color="white"
        transition="background-position ease-in-out 0.25s"
        cursor="pointer"
        _hover={{ backgroundPosition: "233px" }}
        onClick={onOpen}
      >
        <Text as="span">Tell us about your experience.</Text>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Overlay />

        <FormikProvider value={formik}>
          <Form style={{ display: "contents" }}>
            <Modal.Content m="4">
              <Modal.Header>Feedback</Modal.Header>

              <Modal.CloseButton />

              <Modal.Body display="flex" flexDir="column" gap="8">
                <FormControl variant="floating">
                  <Field
                    as={Input}
                    id="email"
                    name="email"
                    type="email"
                    variant="filled"
                    label="Email"
                    placeholder="Optional"
                    _placeholder={{
                      color: 'black.300',
                      fontSize: '0.85rem'
                    }}
                  />
                </FormControl>

                <FormControl
                  variant="floating"
                  isInvalid={
                    !!(formik.touched["message"] && formik.errors["message"])
                  }
                  helper={`Remaining characters: ${500 - formik.values.message.length}`}
                  error={
                    <Text color={"crimson"}>{formik.errors["message"]}</Text>
                  }
                >
                  <Field
                    as={Textarea}
                    id="message"
                    name="message"
                    type="message"
                    variant="filled"
                    label="Message"
                    placeholder="Required"
                    _placeholder={{
                      color: 'black.300',
                      fontSize: '0.85rem'
                    }}
                    validate={(value: string) => {
                      if (!value) {
                        return "Required";
                      }

                      if (value.length > 500) {
                        return "Message length must not exceed 500";
                      }
                    }}
                  />
                </FormControl>

                <FormControl>
                  <Field as={Checkbox} name="contact" spacing="2" pl="2">
                    Please contact me regarding my feedback.
                  </Field>
                </FormControl>
              </Modal.Body>

              <Modal.Footer display="flex" gap="4">
                <Button type="submit" isProcessing={formik.isSubmitting}>
                  Submit
                </Button>

                <Button onClick={onClose} isProcessing={formik.isSubmitting}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal.Content>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  );
};

const AppLayout = ({
  appBoxProps,
  children,
}: {
  appBoxProps?: BoxProps;
  children: React.ReactNode;
}) => {
  if (typeof window === "undefined") {
    return <SplashScreen />;
  }

  return (
    <AuthProvider>
      <UserProvider>
        <ClientProvider>
          <FullscreenContainer id="app">
            <Background />

            {typeof window !== undefined && window.innerWidth > 450 && (
              <CTAButtons />
            )}

            <AppBox {...appBoxProps}>{children}</AppBox>
          </FullscreenContainer>
        </ClientProvider>
      </UserProvider>
    </AuthProvider>
  );
};

export default AppLayout;
