import api from "../api";
import { User } from "./user";

const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserFromSession: build.query<User, undefined>({
      query: () => ({
        url: `session/user`,
        method: "GET",
      }),
      providesTags: (result, err) => {
        return [{ type: "User", id: result?.id }];
      },
    }),
  }),
});

export const { useGetUserFromSessionQuery } = userApi;

export default userApi;
