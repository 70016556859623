import React from "react";
import Text from "../Text/Text";
import { TextProps } from "@chakra-ui/react";

const ErrorComponent = ({
  ...props
}: React.ComponentProps<"span"> & TextProps) => {
  return <Text {...props}>{props.children}</Text>;
};

export default ErrorComponent;
