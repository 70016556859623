import { ApiCacheManager } from "..";
import { deleteCacheRecord, updateCacheRecord } from "../../singletons/Util";
import api, { QueryStringParameters, RecaptchaArgs, TagTypes, Timestamps } from "../api";
import { Comment } from "./comment";
import { File } from "./file";
import { User } from "./user";

export interface Transfer {
  id: string;
  userId: string;
  title: string;
  from: string;
  recipients?: Array<TransferRecipient>;
  toEntry: string;
  linkOnly: boolean;
  message: string;
  password: string;
  expiration: string;
  downloadRestrictions?: {
    maxDownloads?: number;
  };
  files?: Array<File>;
  comments?: Array<Comment>;
  downloads?: Array<TransferDownload>;
  user?: User;
  time: Timestamps;
}

export interface UploadRestrictions {
  maxTransferSize: string;
  maxFileSize: string;
  maxNumberOfFiles: number;
  maxRecipients: number;
  maxFileAvailability: number;
}

export interface TransformedUploadRestrictions {
  maxTransferSize: number;
  maxFileSize: number;
  maxNumberOfFiles: number;
  maxRecipients: number;
  maxFileAvailability: number;
}

export type TransferRecipient = {
  id: string;
  email: string;
  userId: string;
  transferId: string;
  transfer?: Transfer;
  user: User;
  notifications?: TransferRecipientNotification[]
} & Timestamps;

export type TransferRecipientNotification = {
  id: string;
  messageId: string;
  notificationType: 'Delivery' | 'Bounce' | 'Complaint' | 'Reject' | 'Send' | 'DeliveryDelay';
  bounceType?: string;
  complaintType?: string;
  sentOn?: string;
  deliveredOn?: string;
  errorMessage?: string;
} & Timestamps

export type TransferDownload = {
  id: string;
  transferId: string;
  userId: string;
  ip: string;
  userAgent: string;
  transfer?: Transfer;
  user?: User;
} & Timestamps;

export type TransferTotals = {
  size: number;
  files: number;
  downloads: number;
  comments: number;
};

const transferApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTransfer: build.query<Transfer, { transferId: string, params?: QueryStringParameters }>({
      async queryFn(arg, api, extraOptions, baseQuery) {
        if (!arg.params) {
          const data = ApiCacheManager.findById<Transfer>(arg.transferId, 'Transfer')

          if (data) {
            return { data }
          }
        }

        return baseQuery({
          url: `transfer/${arg.transferId}`,
          method: "GET",
          params: arg.params,
        })
      },
      providesTags: (result, err, { transferId }) => {
        return [{ type: "Transfer", id: result?.id }];
      },
    }),
    getTransfersByUser: build.query<
      Transfer[],
      { userId: string; params?: QueryStringParameters }
    >({
      query: ({ userId, params }) => ({
        url: `user/${userId}/transfer`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { userId }) => {
        return (result ?? [])
          .map<{ type: TagTypes; id: string }>((item) => {
            return { type: "Transfer", id: item.id };
          })
          .concat([{ type: "Transfer", id: userId }]);
      },
    }),
    getTransferTotals: build.query<
      TransferTotals,
      { transferId: string; params?: QueryStringParameters }
    >({
      query: ({ transferId, params }) => ({
        url: `transfer/${transferId}/totals`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { transferId }) => {
        return [{ type: "Transfer", id: transferId }];
      },
    }),
    getTransferRecipients: build.query<
      TransferRecipient[],
      { transferId: string; params?: QueryStringParameters }
    >({
      query: ({ transferId, params }) => ({
        url: `transfer/${transferId}/recipients`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { transferId }) => {
        return (result ?? []).map<{ type: TagTypes; id?: string }>((item) => {
          return { type: "TransferRecipient", id: item.id };
        }).concat({ type: "TransferRecipient", id: transferId })
      },
    }),
    getNotificationsByTransferRecipient: build.query<
      TransferRecipientNotification[],
      { transferId: string; recipientId: string; params?: QueryStringParameters }
    >({
      query: ({ transferId, recipientId, params }) => ({
        url: `transfer/${transferId}/recipients/${recipientId}/notifications`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { transferId }) => {
        return (result ?? []).map<{ type: TagTypes; id: string }>((notification) => {
          return { type: 'TransferNotification', id: notification.id }
        }).concat([{ type: "TransferNotification", id: transferId }])
      },
    }),
    getReceivedTransfersByUser: build.query<
      Transfer[],
      { userId: string; params?: QueryStringParameters }
    >({
      query: ({ userId, params }) => ({
        url: `user/${userId}/transfer/received`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { userId }) => {
        return (result ?? [])
          .map<{ type: TagTypes; id: string }>((item) => {
            return { type: "Transfer", id: item.id };
          })
          .concat([{ type: "Transfer", id: userId }]);
      },
    }),
    getTransferTotalsByUser: build.query<
      { size: number; count: number },
      { userId: string; params?: QueryStringParameters }
    >({
      query: ({ userId, params }) => ({
        url: `user/${userId}/transfer/totals`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { userId }) => {
        return ["Transfer", { type: 'Transfer', id: userId }];
      },
    }),
    getTransferFiles: build.query({
      query: ({
        transferId,
        params,
      }: {
        transferId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `transfer/${transferId}/file`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<File>) {
        return baseQueryReturnValue;
      },
      providesTags: (result, err, { transferId }) => {
        return (result ?? [])
          .map<{ type: TagTypes; id: string }>((item) => {
            return { type: "File", id: item.id };
          })
          .concat([{ type: "File", id: transferId }]);
      },
    }),
    getDownloadLink: build.query({
      query: ({ transferId, params }: { transferId: string, params?: { recipient?: string | null } }) => ({
        url: `transfer/${transferId}/download`,
        method: "GET",
        params
      }),
      transformResponse(baseQueryReturnValue: { url: string }) {
        return baseQueryReturnValue;
      },
    }),
    validateTransferPassword: build.mutation<
      {},
      { transferId: string; body: { password: string } }
    >({
      query: ({ transferId, body }) => ({
        url: `transfer/${transferId}/validate/password`,
        method: "POST",
        body,
      }),
    }),
    reportTransfer: build.mutation({
      query: ({
        transferId,
        body,
      }: {
        transferId: string,
        body: {
          message?: string,
          reason: string,
          email?: string
        } & RecaptchaArgs;
      }) => ({
        url: `transfer/${transferId}/report`,
        method: "POST",
        body,
      }),
    }),
    createTransfer: build.mutation({
      query: ({
        body,
        params,
      }: {
        body: {
          from: string;
          title: string;
          recipients: Array<string>;
          files: Array<{ name: string; size: number; type?: string }>;
          password?: string;
          message?: string;
          expiration?: string;
        } & RecaptchaArgs;
        params?: QueryStringParameters;
      }) => ({
        url: `transfer`,
        method: "POST",
        body,
        params,
      }),
      transformResponse(baseQueryReturnValue: Transfer) {
        return baseQueryReturnValue;
      },
      async onQueryStarted({}, api) {
        try {
          const { data } = await api.queryFulfilled

          ApiCacheManager.create({ body: data, tags: ['Transfer'] })
        } catch {}
      },
    }),
    completeTransfer: build.mutation({
      query: ({
        transferId,
      }: {
        transferId: string;
      }) => ({
        url: `transfer/${transferId}/complete`,
        method: "POST",
      }),
      transformResponse(baseQueryReturnValue: Transfer) {
        return baseQueryReturnValue;
      },
      async onQueryStarted({ transferId }, api) {
        try {
          const { data } = await api.queryFulfilled

          ApiCacheManager.update({ id: transferId, body: data, tags: ['Transfer'] })
        } catch {}
      },
    }),
    updateTransfer: build.mutation({
      query: ({
        transferId,
        body,
      }: {
        transferId: string;
        body: {
          password?: string;
          message?: string;
          expiration?: string;
        };
      }) => ({
        url: `transfer/${transferId}`,
        method: "PATCH",
        body,
      }),
      async onQueryStarted({ transferId }, api) {
        try {
          const { data } = await api.queryFulfilled

          ApiCacheManager.update({ id: transferId, body: data, tags: ['Transfer'] })
        } catch {}
      },
    }),
    batchUpdateTransfer: build.mutation<Transfer[], { body: Array<{ transferId: string; password?: string; message?: string; expiration?: string; }> }>({
      query: ({
        body,
      }) => ({
        url: `transfer`,
        method: "PATCH",
        body,
      }),
      async onQueryStarted({ body }, api) {
        try {
          const { data } = await api.queryFulfilled

          for (const item of data) {
            ApiCacheManager.update({ id: item.id, body: item, tags: ['Transfer'] })
          }
        } catch {}
      },
    }),
    deleteTransfer: build.mutation<Transfer, { transferId: string }>({
      query: ({ transferId }) => ({
        url: `transfer/${transferId}`,
        method: "DELETE",
      }),
      async onQueryStarted({ transferId }, api) {
        try {
          await api.queryFulfilled

          ApiCacheManager.delete({ id: transferId, tags: ['Transfer'] })
        } catch {}
      },
    }),
    batchDeleteTransfer: build.mutation<Transfer[], { transferIds: Array<string> }>({
      query: ({ transferIds }) => ({
        url: `transfer`,
        body: transferIds,
        method: "DELETE",
      }),
      invalidatesTags: (result, err, { transferIds }) => {
        return transferIds.map<{ type: TagTypes; id: string }>((id) => {
          return { type: "Transfer", id };
        })
      },
      async onQueryStarted({ transferIds }, api) {
        try {
          await api.queryFulfilled

          for (const id of transferIds) {
            ApiCacheManager.delete({ id, tags: ['Transfer'] })
          }
        } catch {}
      },
    })
  }),
});

export const {
  useCreateTransferMutation,
  useGetDownloadLinkQuery,
  useGetTransferFilesQuery,
  useGetTransferQuery,
  useLazyGetDownloadLinkQuery,
  useGetTransfersByUserQuery,
  useGetReceivedTransfersByUserQuery,
  useGetTransferTotalsByUserQuery,
  useDeleteTransferMutation,
  useGetTransferTotalsQuery,
  useGetTransferRecipientsQuery,
  useValidateTransferPasswordMutation,
  useUpdateTransferMutation,
  useGetNotificationsByTransferRecipientQuery,
  useCompleteTransferMutation,
  useBatchUpdateTransferMutation,
  useBatchDeleteTransferMutation,
  useReportTransferMutation
} = transferApi;

export default transferApi;
