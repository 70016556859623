import { SerializedError } from "@reduxjs/toolkit";
import React from "react";
import ErrorComponent from "./Error";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { TextProps } from "@chakra-ui/react";
import { getErrorMessage } from "../../../store/api";

const APIError = ({
  error = {},
  ...props
}: {
  error?: FetchBaseQueryError | SerializedError | string;
} & React.ComponentProps<"span"> &
  TextProps) => {
  return <ErrorComponent {...props}>{getErrorMessage(error)}</ErrorComponent>;
};

export default APIError;
