import api, { QueryStringParameters, RecaptchaArgs, Timestamps } from "../api";
import { User } from "./user";

export interface Feedback {
  message: string;
  time: Timestamps;
  email?: string;
  contact?: boolean;
  user?: User;
}

const feedbackApi = api.injectEndpoints({
  endpoints: (build) => ({
    createFeedback: build.mutation<Feedback, { body: Omit<Feedback, "user" | "time"> & RecaptchaArgs, params?: QueryStringParameters }>({
      query: ({
        body,
        params,
      }) => ({
        url: `feedback`,
        method: "POST",
        body,
        params,
      }),
    }),
  }),
});

export const { useCreateFeedbackMutation } = feedbackApi;

export default feedbackApi;
