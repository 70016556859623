import React from "react";
import Text from "./Text/Text";
import { TextProps } from "@chakra-ui/react";

const Truncate = ({ ...props }: TextProps) => {
  return (
    <Text
      {...props}
      textOverflow="ellipsis"
      overflow="hidden"
      whiteSpace="nowrap"
      _hover={{
        whiteSpace: "pre-line",
        wordBreak: "break-word",
      }}
    >
      {props.children}
    </Text>
  );
};

export default Truncate;
