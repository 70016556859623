exports.components = {
  "component---src-pages-4-xx-403-tsx": () => import("./../../../src/pages/4xx/403.tsx" /* webpackChunkName: "component---src-pages-4-xx-403-tsx" */),
  "component---src-pages-4-xx-404-tsx": () => import("./../../../src/pages/4xx/404.tsx" /* webpackChunkName: "component---src-pages-4-xx-404-tsx" */),
  "component---src-pages-files-index-tsx": () => import("./../../../src/pages/files/index.tsx" /* webpackChunkName: "component---src-pages-files-index-tsx" */),
  "component---src-pages-files-not-found-tsx": () => import("./../../../src/pages/files/not-found.tsx" /* webpackChunkName: "component---src-pages-files-not-found-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-forgot-confirm-tsx": () => import("./../../../src/pages/login/forgot/confirm.tsx" /* webpackChunkName: "component---src-pages-login-forgot-confirm-tsx" */),
  "component---src-pages-login-forgot-index-tsx": () => import("./../../../src/pages/login/forgot/index.tsx" /* webpackChunkName: "component---src-pages-login-forgot-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-pay-index-tsx": () => import("./../../../src/pages/pay/index.tsx" /* webpackChunkName: "component---src-pages-pay-index-tsx" */),
  "component---src-pages-pay-methods-index-tsx": () => import("./../../../src/pages/pay/methods/index.tsx" /* webpackChunkName: "component---src-pages-pay-methods-index-tsx" */),
  "component---src-pages-plans-index-tsx": () => import("./../../../src/pages/plans/index.tsx" /* webpackChunkName: "component---src-pages-plans-index-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-register-confirm-tsx": () => import("./../../../src/pages/register/confirm.tsx" /* webpackChunkName: "component---src-pages-register-confirm-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-transfers-index-tsx": () => import("./../../../src/pages/transfers/index.tsx" /* webpackChunkName: "component---src-pages-transfers-index-tsx" */),
  "component---src-pages-transfers-not-found-tsx": () => import("./../../../src/pages/transfers/not-found.tsx" /* webpackChunkName: "component---src-pages-transfers-not-found-tsx" */),
  "component---src-pages-unsubscribe-one-click-tsx": () => import("./../../../src/pages/unsubscribe/one-click.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-one-click-tsx" */)
}

