import { Api } from "@reduxjs/toolkit/dist/query"
import { TagTypes } from "../store/api"
import Logger from "./Logger"
import _ from "lodash"
import { EnhancedStore } from "@reduxjs/toolkit"

class CacheManager {
  store: EnhancedStore
  api: Api<any, any, any, any, any>

  constructor (store: EnhancedStore, api: Api<any, any, any, any, any>) {
    this.store = store
    this.api = api
  }

  findById<T> (id: string, tag: TagTypes) {
    const tagEndpoints = this.store.getState()[this.api.reducerPath].provided[tag]

    if (tagEndpoints && id in tagEndpoints) {
      for (const endpoint of tagEndpoints[id]) {
        const response = this.store.getState()[this.api.reducerPath].queries[endpoint]
        const data = response.data

        if (Array.isArray(data)) {
          for (const item of data) {
            if (item && typeof item === 'object' && 'id' in item && item.id === id) {
              return item as T
            }
          }
        } else if (data && typeof data === 'object' && 'id' in data && data.id === id) {
          return data as T
        }
      }
    }
  }

  create({ body, tags }: { body?: Record<string, any>, tags: Array<TagTypes> | Array<{ type: TagTypes, id?: string}> }) {
    try {
      const entries = this.api.util.selectInvalidatedBy(this.store.getState(), tags)
      for (const entry of entries) {
        const originalData = this.store.getState()[this.api.reducerPath].queries[entry.queryCacheKey]

        if (Array.isArray(originalData.data)) {
          this.store.dispatch(
            this.api.util.upsertQueryData(entry.endpointName, entry.originalArgs, [body, ...originalData.data])
          )
        }
      }
    } catch (e: unknown) {
      Logger.debug('Failed to update cache entries', e)
    }
  }

  update({ id, body, tags }: { id: string, body: Record<string, any>, tags: Array<TagTypes> | Array<{ type: TagTypes, id?: string}> }) {
    const patchResults: any[] = []

    try {
      const entries = this.api.util.selectInvalidatedBy(this.store.getState(), tags)
      for (const entry of entries) {
        patchResults.push(
          this.store.dispatch(
            this.api.util.updateQueryData(entry.endpointName, entry.originalArgs, (draft: any) => {
              if (draft) {
                if (Array.isArray(draft)) {
                  for (let item of draft) {
                    if (item.id === id) {
                      Object.assign(item, body)
                    }
                  }
                } else if (draft) {
                  Object.assign(draft, body)
                }
              }

              return draft
            })
          )
        )
      }
    } catch (e: unknown) {
      Logger.debug('Failed to update cache entries', e)

      patchResults.forEach((result) => result.undo())
    }

    return patchResults
  }

  delete({ id, tags }: { id: string, tags: Array<TagTypes> | Array<{ type: TagTypes, id?: string}> }) {
    const patchResults: any[] = []

    try {
      const entries = this.api.util.selectInvalidatedBy(this.store.getState(), tags)
      for (const entry of entries) {
        patchResults.push(
          this.store.dispatch(
            // TS doesn't like that endpointName is just a string, when updateQueryData is looking for a particular endpoint set.
            // However, it should always be of that set... because... that's how it came to be.
            // @ts-ignore
            this.api.util.updateQueryData<any>(entry.endpointName, entry.originalArgs, (draft) => {
              if (draft) {
                if (Array.isArray(draft)) {
                  draft = draft.filter((item) => {
                    return item.id !== id
                  });
                } else {
                  delete draft[id]
                }
              }

              return draft
            })
          )
        )
      }
    } catch (e: unknown) {
      Logger.debug('Failed to update cache entries', e)

      patchResults.forEach((result) => result.undo())
    }

    return patchResults
  }
}

export default CacheManager