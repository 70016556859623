import Uppy, { Meta, State, UppyFile } from "@uppy/core";
import Webcam from "@uppy/webcam";
import React from "react";
import { AuthContext } from "./AuthProvider";

import { useToast } from "@chakra-ui/react";
import Audio from "@uppy/audio";
import ImageEditor from "@uppy/image-editor";
import ScreenCapture from "@uppy/screen-capture";
import { FormikContextType, useFormik } from "formik";
import Logger from "../singletons/Logger";
import store, { useAppDispatch } from "../store";
import api, { getErrorMessage } from "../store/api";
import { useCreateTransferMutation } from "../store/api/transfer";
import uppyApi from "../store/api/uppy";

// CSS
import "@uppy/audio/dist/style.min.css";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/image-editor/dist/style.min.css";
import "@uppy/screen-capture/dist/style.min.css";
import "@uppy/webcam/dist/style.min.css";
import { useSiteMetadata } from "../components/common/Seo";
import BrowserNotification from "../singletons/BrowserNotification";
import { RecaptchaToken } from "../singletons/Recaptcha";
import { UserContext } from "./UserProvider";
import "./css/Uppy.css";
import AwsS3Multipart from "@uppy/aws-s3";

export const uppy = new Uppy({
  debug: process.env.NODE_ENV !== "production",
  autoProceed: false,
  allowMultipleUploadBatches: false,
  restrictions: {
    maxTotalFileSize: 2147483648,
    maxNumberOfFiles: 5,
    minNumberOfFiles: 1,
  },
});

// Server side cannot generate most of this code.
// It's also not necessary, as uppy specifics are client specific.
if (typeof window !== "undefined") {
  uppy
    .use(Webcam)
    .use(AwsS3Multipart, {
      shouldUseMultipart: true,
      async completeMultipartUpload(file, opts) {
        try {
          const response = await store.dispatch(
            uppyApi.endpoints.completeMultipartUpload.initiate(
              {
                uploadId: opts.uploadId,
                body: { Key: opts.key, Parts: opts.parts },
              },
              { track: false },
            ),
          );

          Logger.debug("Complete Response", { response });

          if ("error" in response) {
            throw new Error(getErrorMessage(response.error));
          }

          return response.data;
        } catch (e) {
          Logger.debug(e);

          throw e;
        }
      },
      async signPart(file, opts) {
        try {
          const response = await store.dispatch(
            uppyApi.endpoints.signPart.initiate({
              uploadId: opts.uploadId,
              partNumber: opts.partNumber,
              params: { key: opts.key },
            }),
          );

          Logger.debug("Sign Response", { response });

          if (response.isError) {
            throw new Error(getErrorMessage(response.error));
          }

          if (!response.data) {
            throw new Error("No data was returned for signPart");
          }

          return response.data;
        } catch (e) {
          Logger.debug(e);

          throw e;
        }
      },
      async listParts(file, opts) {
        try {
          const response = await store.dispatch(
            uppyApi.endpoints.listParts.initiate({
              uploadId: opts.uploadId,
              params: { key: opts.key },
            }),
          );

          Logger.debug("List Response", { response });

          if (response.isError) {
            throw new Error(getErrorMessage(response.error));
          }

          if (!response.data) {
            throw new Error("No data was returned for listParts");
          }

          return response.data;
        } catch (e) {
          Logger.debug(e);

          throw e;
        }
      },
      async abortMultipartUpload(file, opts) {
        try {
          const response = await store.dispatch(
            uppyApi.endpoints.abortMultipartUpload.initiate(
              { uploadId: opts.uploadId, body: { Key: opts.key } },
              { track: false },
            ),
          );

          Logger.debug("Abort Response", { response });

          if ("error" in response) {
            throw new Error(getErrorMessage(response.error));
          }

          return response.data;
        } catch (e) {
          Logger.debug(e);

          throw e;
        }
      },
      async createMultipartUpload(file) {
        try {
          const response = await store.dispatch(
            uppyApi.endpoints.createMultipartUpload.initiate(
              {
                body: {
                  filename: file.name ?? 'Untitled',
                  type: file.type,
                  size: file.size ?? 0,
                  meta: file.meta,
                },
              },
              { track: false },
            ),
          );

          Logger.debug("Create Response", { response });

          if ("error" in response) {
            throw new Error(getErrorMessage(response.error));
          }

          return response.data;
        } catch (e) {
          Logger.debug(e);

          throw e;
        }
      },
    })
    .use(ImageEditor, {})
    .use(ScreenCapture, {})
    .use(Audio, {});

  uppy.on("upload-success", (file, resp) => {
    if (!file) {
      return;
    }

    uppy.setFileMeta(file.id, {
      ...file.meta,
      uploadedAt: Date.now(),
    });
  });
}

export interface IUppyFormik {
  title: string;
  from: string;
  recipients: string[];
  toEntry: string;
  linkOnly: boolean;
  message: string;
  password: string;
  expiration?: string;
}

export interface IUppy {
  instance: Uppy;
  formik: FormikContextType<IUppyFormik>;
  startTransfer: () => Promise<void>;
}

export const UppyContext = React.createContext({
  instance: uppy,
} as IUppy);

export const UppyProvider = ({ ...props }) => {
  const { isLoggedIn, userData } = React.useContext(AuthContext);
  const user = React.useContext(UserContext);

  const [createTransfer] = useCreateTransferMutation();

  const metadata = useSiteMetadata();

  const appDispatch = useAppDispatch();

  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      title: "",
      from: isLoggedIn()
        ? userData?.UserAttributes.find((attr) => attr.Name === "email")?.Value
        : "",
      recipients: [],
      toEntry: "",
      linkOnly: false,
      message: "",
      password: "",
      expiration: undefined,
    } as IUppyFormik,
    async onSubmit(values, actions) {
      try {
        Logger.debug("Submitting Uppy Formik Form", formik.status);

        if (!values.linkOnly && values.recipients.length <= 0) {
          if (!values.toEntry) {
            throw new Error("Please provide at least one recipient", {
              cause: "toEntry",
            });
          } else {
            values.recipients = [values.toEntry];
          }
        }

        const response = await createTransfer({
          body: {
            ...formik.values,
            recipients: values.recipients,
            files: uppy.getFiles().map((file) => {
              return {
                name: file.name ?? 'Untitled',
                size: file.size ?? 0,
                type: file.type,
              };
            }),
            recaptcha: {
              token: await RecaptchaToken.generate({ action: "UPLOAD" }),
              action: "UPLOAD",
            },
          },
        });

        if ("error" in response) {
          throw new Error(getErrorMessage(response.error));
        }

        for (const key in uppy.getFiles()) {
          const file = uppy.getFiles()[key];

          uppy.setFileMeta(file.id, {
            ...uppy.getFile(file.id).meta,
            startedAt: Date.now(),
            transferId: response.data.id,
          });
        }

        formik.setStatus("success");
      } catch (e: any) {
        Logger.error(e, formik.status);

        if (e instanceof Error) {
          if (typeof e.cause === "string") {
            actions.setFieldError(e.cause, e.message);
          } else {
            toast({
              title: "An error occurred",
              description: e.message,
              status: "error",
            });
          }
        }

        formik.setStatus("error");

        // TODO: Toast
      } finally {
        Logger.debug("Finished submitting", formik.status);

        actions.setSubmitting(false);
      }
    },
  });

  const startTransfer = async () => {
    Logger.debug("Starting transfer");

    uppy.upload();
  };

  React.useEffect(() => {
    const handler = () => {
      Logger.debug("Invalidating Transfer Tags");

      appDispatch(api.util.invalidateTags(["Transfer"]));
    };

    uppy.on("complete", handler);
    uppy.on("error", handler);
    uppy.on("cancel-all", handler);
    uppy.on("upload", handler);

    return () => {
      uppy.off("complete", handler);
      uppy.off("error", handler);
      uppy.off("cancel-all", handler);
      uppy.off("upload", handler);
    };
  }, []);

  React.useEffect(() => {
    const handler = (
      file: UppyFile<Record<string, unknown>, Record<string, unknown>>,
    ) => {
      Logger.debug("file-added or file-removed handler called");

      if (uppy.getFiles().length <= 0) {
        Logger.debug("Setting title to an empty string");
        formik.setFieldValue("title", "");

        Logger.debug("Resetting the formik form");
        formik.resetForm();

        Logger.debug("Setting formik status to undefined");
        formik.setStatus(undefined);

        document.title = `${metadata.title} | ${metadata.description}`;
      }

      if (!formik.values.title) {
        Logger.debug(
          "Setting title of transfer to first file in list",
          uppy.getFiles().length > 0 ? uppy.getFiles()[0].name : "",
        );
        formik.setFieldValue(
          "title",
          uppy.getFiles().length > 0 ? uppy.getFiles()[0].name : "",
        );
      }
    };

    uppy.on("file-added", handler);
    uppy.on("file-removed", handler);

    return () => {
      uppy.off("file-added", handler);
      uppy.off("file-removed", handler);
    };
  }, []);

  React.useEffect(() => {
    const handler = () => {
      Logger.debug("Complete handler called");

      if (document.visibilityState === "hidden") {
        if (uppy.getState().error) {
          document.title = `${metadata.title} | Transfer Failed`;

          if (
            user?.notificationPreferences?.browser?.onTransferComplete &&
            BrowserNotification.supported
          ) {
            BrowserNotification.notify({
              title: "Your transfer has failed to upload",
              options: {
                body: uppy.getState().error ?? undefined,
                closeOnVisible: true,
                showAppOnClick: true,
              },
            });
          }
        } else {
          document.title = `${metadata.title} | Transfer Complete`;

          if (
            user?.notificationPreferences?.browser?.onTransferComplete &&
            BrowserNotification.supported
          ) {
            BrowserNotification.notify({
              title: "Your transfer is complete",
              options: { closeOnVisible: true, showAppOnClick: true },
            });
          }
        }
      }
    };

    uppy.on("complete", handler);

    return () => {
      uppy.off("complete", handler);
    };
  }, []);

  React.useEffect(() => {
    const handler = (progress: number) => {
      if (typeof window !== "undefined") {
        document.title = `${metadata.title} | Sending Transfer (${progress}%)`;
      }
    };

    uppy.on("progress", handler);

    return () => {
      uppy.off("progress", handler);
    };
  }, []);

  // Update restrictions when the user changes
  React.useEffect(() => {
    if (user?.uploadRestrictions) {
      uppy.setOptions({
        restrictions: {
          maxTotalFileSize: user?.uploadRestrictions.maxTransferSize,
          maxNumberOfFiles: user?.uploadRestrictions.maxNumberOfFiles,
          maxFileSize: user?.uploadRestrictions.maxFileSize,
          minNumberOfFiles: 1,
        },
      });
    }
  }, [user?.uploadRestrictions]);

  // As a provider for consistency
  return (
    <UppyContext.Provider
      value={{
        instance: uppy,
        formik,
        startTransfer,
      }}
    >
      {props.children}
    </UppyContext.Provider>
  );
};
